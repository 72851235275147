.root-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.AppHeader {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 12px;
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
}

.active-icon {
  color: transparent !important;
  background: linear-gradient(180deg, #9027e2 0%, #00248c 100%);
  border-radius: 50%;
}

.applyBtn {
  background: linear-gradient(90deg, #9027e2 0%, #00248c 100%);
  color: white !important;
  border: 0;
  font-weight: 500 !important;
  padding: 10px 20px !important;
  border-radius: 4px !important;
  border: 1px solid #9027E2 !important;
  width: 80px;
  cursor: pointer;

}

.cancelBtn {
  border: 1px solid #9027E2 !important;
  background: white !important;
  color: #142598 !important;
  font-weight: 500 !important;
  padding: 10px 20px !important;
  border-radius: 4px !important;
  width: 80px;
  cursor: pointer;
}

.cursor {
  pointer-events: inherit !important;
  cursor: wait !important;
}

.mt-3 {
  margin-top: 24px !important;
}

.m-0 {
  padding-left: 15px;
  padding-top: 3px;
  margin: 0 !important;
}



.leftItem {
  color: black;
  border: 1px solid rgba(193, 201, 210, 1);
  border-right: 0;
  border-radius: 4;
}

.w-100 {
  width: 100%;
}