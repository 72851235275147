.chatContainer {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: white;
  border-radius: 10px;
}

.prefillQues {
  background-color: #e4edff;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.chatHeader {
  background-image: linear-gradient(to right, #3870e0, #00248c);
  padding: 10px 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  gap: 10px;
  align-items: center;

  img {
    height: 100%;
    width: 100%;
    max-width: 64px;
  }

  p {
    color: white;
    font-size: 20px;
    margin: 0;
  }
}

.chatBody {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 5;
  background-color: #f6f9ff;
  margin: 10px;
  /* min-height: 40vh; */
  /* max-height: 50vh; */
  height: 40vh;
  overflow-y: scroll;
}

.userMsg {
  background-color: #ffffff;
  color: #00248c;
  max-width: 80%;
  border: 1px solid #d4e3ff;
  padding: 16px 12px 16px 12px;
  gap: 10px;
  border-radius: 12px 0px 12px 12px;
  border: 1px 0px 0px 0px;
  /* opacity: 0px; */
  max-width: 400px;
  text-wrap: wrap;
  overflow-wrap: break-word;
}

.userMsgContainer {
  display: flex;
  justify-content: end;
}

.botMsgContainer {
  display: flex;
  justify-content: start;
}

.botMsg {
  background-color: #e4edff;
  color: #00248c;
  max-width: 90%;
  border: 1px solid #d4e3ff;
  padding: 16px 12px 16px 12px;
  gap: 10px;
  border-radius: 0px 12px 12px 12px;
  border: 1px 0px 0px 0px;
}

.chatFooter {
  display: flex;
  padding: 10px;
  gap: 10px;
}

.sendBtn {
  padding: 10px;
  background: linear-gradient(to right, #3870e0, #00248c);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  height: 40px;
  width: 40px;
  cursor: pointer;
}

.chat {
  height: 100%;
}