.css-1iz286x {
    background: url('../../../../assets/images/solutions/drawer.png');
    background-size: cover;
    height: 100vh;
    width: 335px;
    margin-left: -10px;
    color: white !important;
    background-position: center;
}

.css-2a1v0z .MuiDrawer-paper {
    padding-bottom: 0px !important;
}

.css-1hrczz4-MuiButtonBase-root-MuiListItemButton-root:hover,
.css-9vnbm8:hover,
.css-1hqbwtv:hover {
    background-color: #9027E2;
}

.css-agq12c-MuiButtonBase-root-MuiListItemButton-root:hover,
.css-9vnbm8:hover,
.css-1hqbwtv:hover,
.css-es6vtg-MuiButtonBase-root-MuiListItemButton-root:hover {
    background-color: #9027E2;

}

.css-1utb4p0-MuiDrawer-docked .MuiDrawer-paper {
    padding-bottom: 0 !important;
}

.css-1f4u86u-MuiTypography-root {
    color: white !important;
}

.css-1sx7tm3-MuiTypography-root {
    color: white !important;
}

.css-agq12c-MuiButtonBase-root-MuiListItemButton-root.Mui-selected,
.css-9vnbm8.Mui-selected {
    background: white !important;
    color: #9027E2 !important;

    .css-13wn93r-MuiTypography-root,
    .css-v3mmnb {
        color: #00248C !important;
    }
}

.css-1hrczz4-MuiButtonBase-root-MuiListItemButton-root.Mui-selected .MuiListItemIcon-root {
    color: white !important;
}

.css-1hqbwtv.Mui-selected .MuiListItemIcon-root {
    color: white !important;
}

.css-1giikn0 {
    color: white !important;
}




.css-s9qt9o-MuiListItemIcon-root {
    color: white;
}

.css-12xvifp,
.css-nm9qzb {
    color: white !important;
}

.css-1iz286x {
    .anticon-down {
        color: white !important;
    }

    .anticon-up {
        color: white !important;
    }
}