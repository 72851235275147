#phoneNumber-signup-label.MuiInputLabel-shrink {
  margin-left: -6px !important;
}

/* Customize dot colors */
.slick-dots li button:before {
  color: white;
  font-size: 12px;
  line-height: 20px;
  width: 20px;
  height: 20px;
}

.secondary-button {
  color: '#00248C';
  border: '1px solid  #5A6893' !important;
  background-color: white !important;
}

.slick-dots li.slick-active button:before {
  color: white;
  border-color: white;
}
.loginvisible:focus-visible {
  outline: 2px solid #9027e2;
  outline-offset: 2px;
}
.primary-button {
  background: linear-gradient(132deg, #1ca2f6 13.02%, #607ef0 48.44%, #9027e2 100%) !important;
  color: white;
}

@media screen and (min-width: 992px) {
  .prt-5 {
    position: relative;
    top: 30px;
  }

  .prt-6 {
    position: relative;
    top: 36px;
  }

  .d-flex {
    display: flex;
  }

  .justify-content-between {
    justify-content: space-between;
  }

  .justify-content-center {
    justify-content: center;
  }

  .w-100 {
    width: 100%;
  }

  .text-center {
    text-align: center;
    color: white;
  }

  .p-5 {
    /* padding-right: 20px; */
    width: 70%;
  }
}
