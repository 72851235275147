h6.MuiTypography-subtitle2 {
  display: none;
}
.drawerClose + .Mui-selected > div > span[role='img'] {
  color: white !important;
}
.drawerClose + .Mui-selected > .MuiListItemIcon-root {
  background: linear-gradient(89.91deg, #9027e2 0.04%, #00248c 99.89%) !important;
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}
.justify-content-center {
  justify-content: center;
}

.flex-column {
  flex-direction: column;
}

.h-100 {
  height: calc(90vh + 2%);
}


