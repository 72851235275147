.menu-container {
  position: relative;
  display: inline-block;
}

.menu-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.align-items-center {
  align-items: center !important;
}

.mr-2 {
  margin-right: 8px !important;
}
.prl-2 {
  position: relative;
  left: 8px;
  color: white;
  text-decoration: none;
}

.menu-content {
  position: absolute;
  top: 45px;
  left: 110px;
  transform: translateX(-50%);
  max-width: 300px;
  min-width: 240px;
  background: linear-gradient(180deg, #1f48be 0%, #3460de 100%);
  border-radius: 8px;
  /* padding: 10px; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

@media screen and (min-width: 768px) {
  .mt-md-0{
    margin-top: 0 !important;
  }
}

.menu-heading {
  font-size: 16px;
  color: white;
  margin-bottom: 10px;
  padding: 10px 0px 10px 0px;
  position: relative;
  top: 5px;
}

.switcher-btn{
  border: 0;
  background: transparent;
  cursor: pointer;
}

@media screen and (max-width:992px) {
  .menu-heading{
  padding: 10px 0px 10px 30px;

  }
}

.menu-list {
  background-color: #00248c;
  border-radius: 0px 0px 8px 8px;
  list-style: none;
  padding: 10px;
  margin: 0;
}

.menu-list li {
  color: white;
  margin-bottom: 5px;
  padding: 5px 10px;
  border-radius: 4px;
}

.pointer {
  position: absolute;
  top: -19px;
  left: 10%;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #1f48be transparent;
}
