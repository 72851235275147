.overlay-container {
  position: relative;
  display: inline-block; /* or block, flex, etc. depending on your use case */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 80vh;
  /* background-color: rgba(0, 0, 0, 0.9); Black with 50% opacity */
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  background: rgba(227, 239, 255, 0.5);
  backdrop-filter: blur(4px);
}

.content {
  /* width: 60vw; */
  padding: 10px;
  filter: blur(3px);
}

.overlay-button {
  padding: 10px 30px;
  background-color: #fff;
  color: #333;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.overlay-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 150px;
}

.content {
  height: 80vh;
  overflow: hidden;
}
