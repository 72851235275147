.table {
  display: table;
  width: 260px;
  background-color: #fff;
  color: #000;
  border-spacing: 2px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}
.cellHeader {
  display: table-cell;
  padding: 1px;
  overflow: hidden;
}
.cell {
  display: table-cell;
  padding: 1px;
  overflow: hidden;
  font-size: 10px;
}
.cell1 {
  display: table-cell;
  padding: 1px;
  width: 150px;
  font-size: 10px;
}
.cell,
td {
  white-space: nowrap;
}
.box {
  float: left;
  height: 10px;
  width: 10px;
  clear: both;
  padding: 1px;
}
.red {
  background-color: red;
}
.header {
  display: flex;
}
.cellText {
  width: 120px;
  margin-left: 14px;
  margin-top: -4px;
}
.footer {
  border-top: 1px solid #8c8c8c;
  padding-top: 5px;
}
.bold {
  font-weight: bold;
}
.horizontal-line {
  border-top: 1px solid #8c8c8c;
  margin-top: 4px;
}
.f-15 {
  font-size: 15px;
}
.row1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.p-2 {
  padding: 2px;
}
